<template>
  <div class="container-mod">
    <div
      class="content"
      v-if="liberaTela && terminouConsulta && resultadoModelo.erro == undefined"
    >
      <div ref="pdfContent">
        <div class="header-box">
          <div class="container-header">
            <div class="header-text">
              <span class="text-top">
                {{ tituloCodModelo }}
              </span>
              <span class="text-desc">
                {{ tituloDescModelo }}
              </span>
            </div>
            <div class="header-icons">
              <span
                class="icon-btn"
                id="download-pdf"
                @mouseover="showPdf = true"
                @mouseout="showPdf = false"
                :class="{ 'button-disabled': liberaSpinner }"
                @click="generatePDF()"
              >
                <img
                  :src="showPdf ? iconePDFAzul : iconePDFBranco"
                  alt=""
                  class="icon-header"
                />
              </span>
              <b-tooltip
                v-if="exibirTootipsConsultas"
                :target="'download-pdf'"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tooltipTexts"
                boundary="window"
              >
                Gerar PDF
              </b-tooltip>
              <span
                class="icon-btn"
                @mouseover="showPasso0 = true"
                @mouseout="showPasso0 = false"
                id="modelos-inicio"
                @click="goInicio"
              >
                <img
                  :src="showPasso0 ? iconePasso0Azul : iconePasso0Branco"
                  alt=""
                  class="icon-header"
                />
              </span>
              <b-tooltip
                v-if="exibirTootipsConsultas"
                :target="'modelos-inicio'"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tooltipTexts"
                boundary="window"
              >
                Retornar ao início
              </b-tooltip>
              <span
                v-if="trilhaSelecionada.id == 1"
                class="icon-btn"
                @mouseover="showPasso1 = true"
                @mouseout="showPasso1 = false"
                id="novo-param"
                @click="goModelos"
              >
                <img
                  :src="showPasso1 ? iconePasso1Azul : iconePasso1Branco"
                  alt=""
                  class="icon-header"
                />
              </span>
              <b-tooltip
                v-if="exibirTootipsConsultas && trilhaSelecionada.id == 1"
                :target="'novo-param'"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tooltipTexts"
                boundary="window"
              >
                Lista de modelos
              </b-tooltip>
              <span
                v-if="trilhaSelecionada.id == 1"
                class="icon-btn"
                id="nova-consulta"
                @mouseover="showPasso2 = true"
                @mouseout="showPasso2 = false"
                @click="goInputs"
              >
                <img
                  :src="showPasso2 ? iconePasso2Azul : iconePasso2Branco"
                  alt=""
                  class="icon-header"
                />
              </span>
              <b-tooltip
                v-if="exibirTootipsConsultas && trilhaSelecionada.id == 1"
                :target="'nova-consulta'"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tooltipTexts"
                boundary="window"
              >
                Nova consulta
              </b-tooltip>
            </div>
          </div>
          <img
            class="icon-param"
            :class="{ close: exibirParametros }"
            id="openParams"
            :src="iconParam"
            @click="exibirParametros = !exibirParametros"
          />
          <b-tooltip
            v-if="exibirTootipsConsultas"
            :target="'openParams'"
            triggers="hover"
            placement="bottom"
            variant="dark"
            class="tooltipTexts"
            boundary="window"
          >
            Exibir informações sobre a consulta
          </b-tooltip>
        </div>
        <div>
          <BlocoParametros
            v-if="exibirParametros"
            :ref="'blocopfParam'"
            :resultados="resultadoModelo.sumario_consulta"
            :codAgr="codigo_agrupador"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />

          <BlocoInfoPJ1
            v-if="resultadoConsultaAdd.BOXSUPPJ001 != undefined"
            ref="blocopjInfo1"
            :resultados="resultadoConsultaAdd.BOXSUPPJ001.atributos"
            :listaBlocos="listaBlocos"
            :codigo_agrupador="codigo_agrupador"
            :exibirTootipsConsultas="exibirTootipsConsultas"
            :gerandoPdf="gerandoPdf"
          />

          <BlocoPFDadosCadastrais
            v-if="resultadoConsultaAdd.BOXSUPPF001 != undefined"
            ref="blocopfDadosCadastrais"
            :listaBlocos="listaBlocos"
            :resultados="resultadoConsultaAdd.BOXSUPPF001.atributos"
            :valorCPF="valorCPF"
            :nomeCompleto="nomeCompleto"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />

          <BlocoScore
            ref="blocopfScore"
            v-if="
              resultadoModelo.atributos != undefined &&
              resultadoModelo.atributos.score != undefined
            "
            :resultados="resultadoModelo.atributos"
            :listaBlocos="listaBlocos"
            :modeloSelecionado="modeloSelecionado"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />
          <BlocoRenda
            ref="blocopfRenda"
            v-if="
              resultadoModelo.atributos != undefined &&
              (resultadoModelo.atributos.renda_presumida != undefined ||
                resultadoModelo.atributos.faturamento_presumido != undefined)
            "
            :resultados="resultadoModelo.atributos"
            :listaBlocos="listaBlocos"
            :modeloSelecionado="modeloSelecionado"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />
          <BlocoPFMapaRiscos
            ref="blocopfMapaRiscos"
            v-if="resultadoConsultaAdd.BOXSUPPF005 != undefined"
            :listaBlocos="listaBlocos"
            :resultados="resultadoConsultaAdd.BOXSUPPF005.atributos"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />

          <BlocoInfoPJ2
            v-if="resultadoConsultaAdd.BOXSUPPJ002 != undefined"
            ref="blocopjInfo2"
            :resultados="resultadoConsultaAdd.BOXSUPPJ002.atributos"
            :listaBlocos="listaBlocos"
            :codigo_agrupador="codigo_agrupador"
            :exibirTootipsConsultas="exibirTootipsConsultas"
            :gerandoPdf="gerandoPdf"
          />

          <BlocoKYC
            v-if="
              resultadoConsultaAdd.BOXKYC001 != undefined ||
              resultadoConsultaAdd.BOXKYC002 != undefined ||
              resultadoConsultaAdd.BOXKYC003 != undefined ||
              resultadoConsultaAdd.BOXKYC004 != undefined
            "
            ref="blocopjKyc"
            :resultadoConsultaAdd="resultadoConsultaAdd"
            :listaBlocos="listaBlocos"
            :codigo_agrupador="codigo_agrupador"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />

          <BlocoPJAlertas
            ref="blocopjAlertas"
            v-if="liberaBlocoAlerta"
            :listaBlocos="listaBlocos"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />

          <PrefTooltipTela @recebePrefTooltips="recebePrefTooltips" />
        </div>
      </div>
    </div>
    <div
      v-else-if="
        resultadoModelo.atributos == undefined &&
        liberaTela &&
        terminouConsulta &&
        resultadoModelo.erro != undefined
      "
      class="nao_encontrado"
    >
      <span class="material-icons-outlined sad">sentiment_dissatisfied</span>

      {{ resultadoModelo.erro.erro }}
    </div>
    <div class="spinnerBG" v-if="liberaSpinner">
      <Loading class="spinner" />
    </div>
    <div v-if="!terminouConsulta">
      <Loading class="loading" />
    </div>
  </div>
</template>

<script>
import { userPreferenceStore } from "@/stores/userPreference";
import { produtosStore } from "@/stores/produtos";
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { mapActions, mapWritableState } from "pinia";
import { mascaras } from "@/helpers/mascaras";
import Loading from "@/components/Loading.vue";
import BlocoParametros from "@/components/Modelos/BlocoParametros.vue";

import BlocoScore from "@/components/Modelos/BlocoScore.vue";
import BlocoRenda from "@/components/Modelos/BlocoRenda.vue";
import BlocoPFDadosCadastrais from "@/components/SuperBox/BlocoPFDadosCadastrais.vue";
import BlocoInfoPJ1 from "@/components/Modelos/BlocoInfoPJ1.vue";
import BlocoInfoPJ2 from "@/components/Modelos/BlocoInfoPJ2.vue";
import BlocoKYC from "@/components/Modelos/BlocoKYC.vue";
import BlocoPJAlertas from "@/components/Modelos/BlocoPJAlertas.vue";
import PrefTooltipTela from "@/pages/MinhaConta/PrefTooltipTela.vue";

import BlocoPFMapaRiscos from "@/components/SuperBox/BlocoPFMapaRiscos.vue";

import { createPDFZip, createPDF } from "@/services/planilha.service.js";

export default {
  name: "ResultadosModelos",

  props: {
    opcao_inicial: String,
    modeloSelecionado: Object,
    resultadoModelo: Object,
    consultasSelecionadas: Array,
    resultadoConsultaAdd: Object,
    terminouConsulta: Boolean,
    liberaBlocoAlerta: Boolean,
    modelos: Array,
  },

  data() {
    return {
      listaBlocos: [],
      liberaTela: false,
      liberaSpinner: false,
      exibirParametros: false,
      iconParam: "/img/superbox/icon-parametros.svg",
      fundoHeader: "/img/modelos/Topo_Modelos.png",

      iconePDFAzul: "/img/modelos/icone-pdfAzul.png",
      iconePDFBranco: "/img/modelos/icone-pdfBranco.png",

      iconePasso0Azul: "/img/modelos/icone-FlechaAzul.png",
      iconePasso0Branco: "/img/modelos/icone-FlechaBranco.png",

      iconePasso1Azul: "/img/modelos/icone-ListaAzul.png",
      iconePasso1Branco: "/img/modelos/icone-ListaBranco.png",

      iconePasso2Azul: "/img/modelos/icone-novaConsultaAzul.png",
      iconePasso2Branco: "/img/modelos/icone-novaConsultaBranco.png",

      showPdf: false,
      showPasso0: false,
      showPasso1: false,
      showPasso2: false,
      gerandoPdf: false,

      tipoDocumento: "",

      tituloCodModelo: "",
      tituloDescModelo: "",

      nomeCompleto: "",
      valorCPF: "",
    };
  },
  components: {
    BlocoParametros,
    BlocoScore,
    BlocoRenda,
    BlocoPFDadosCadastrais,
    BlocoPFMapaRiscos,
    Loading,
    BlocoInfoPJ1,
    BlocoInfoPJ2,
    BlocoPJAlertas,
    PrefTooltipTela,
    BlocoKYC,
  },

  async mounted() {
    if (this.opcao_inicial == "") {
      this.$router.push("/consultas/modelos/0");
    }

    this.ocultarDados = await this.syncPreferenciaOcultarDados();
    this.exibirTootipsConsultas = await this.syncPreferenciaConsultasTooltips();
    this.tipoDownloadConsulta = await this.syncPreferenciaTipoDownload();
  },

  watch: {
    terminouConsulta: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          this.iniciaTela();
        }
      },
    },

    trilhaSelecionada: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          if (this.codigo_agrupador) {
            if (
              this.trilhaSelecionada.cod_agrupador != "loading" &&
              this.trilhaSelecionada.consulta == "Modelos" &&
              this.consultasTrilha.length > 1
            ) {
              this.exibirParametros = false;
              this.iniciaTela();
            }
          }
        }
      },
      deep: true,
    },
  },

  methods: {
    iniciaTela() {
      this.montaTitulo();

      this.listaBlocos = [];
      this.constroiBlocos();

      this.liberaTela = true;
      this.$nextTick(() => {
        // setTimeout(() => {
        this.abreBlocos();
        // }, 2000);
      });

      this.liberaSpinner = false;
    },

    montaTitulo() {
      var tipoPessoa = "";
      this.tipoDocumento = this.modeloSelecionado.TipoPessoaConsulta;
      if (this.tipoDocumento == "Física") {
        tipoPessoa = "PF";
      } else if (this.tipoDocumento == "Jurídica") {
        tipoPessoa = "PJ";
      }

      this.tituloCodModelo = `Modelo ${tipoPessoa}: ${this.modeloSelecionado.Valor}`;
      this.tituloDescModelo = this.modeloSelecionado.Descricao;
    },

    async generatePDF() {
      this.liberaSpinner = true;
      this.gerandoPdf = true;
      this.abreBlocos();
      this.exibirParametros = true;

      await this.viewMapsAndReturn();
      const targetElement = this.$refs.pdfContent;
      const codAgr = this.codigo_agrupador;
      let nomeArquivo = `outbox-modelo-${codAgr}`;

      if (this.tipoDownloadConsulta == "zip") {
        createPDFZip(targetElement, nomeArquivo);
      } else {
        createPDF(targetElement, nomeArquivo);
      }
      setTimeout(() => {
        this.liberaSpinner = false;
        this.exibirParametros = false;
        this.gerandoPdf = false;
      }, 3000);
    },

    async viewMapsAndReturn() {
      const maps = document.getElementsByClassName("vue-map-container");
      const delay = (ms) => new Promise((res) => setTimeout(res, ms));

      for (const map of maps) {
        map.scrollIntoView();
        await delay(2000);
      }
      window.scrollTo({
        top: 0,
        left: 0,
      });
    },

    goInicio() {
      this.$router.push("/consultas/modelos/0");
    },

    goModelos() {
      this.$router.push("/consultas/modelos/1");
    },

    goInputs() {
      this.$router.push("/consultas/modelos/2");
    },

    constroiBlocos() {
      this.liberaSpinner = true;
      // let contadorBloco = 2;
      // `Bloco ${contadorBloco++}`
      if (this.resultadoModelo.atributos != undefined) {
        if (this.resultadoModelo.atributos.score != undefined) {
          const blocoModelo = {
            box: this.resultadoModelo.sumario_consulta.parametros.cod_ref,
            id: "2",
            titulo: "Bloco 02",
            descricao: "Avaliação por Score",
            imagem: "/img/modelos/icone-bloco-score.png",
          };
          this.listaBlocos.push(blocoModelo);
        }

        if (
          this.resultadoModelo.atributos.renda_presumida != undefined ||
          this.resultadoModelo.atributos.faturamento_presumido != undefined
        ) {
          const blocoModelo = {
            box: this.resultadoModelo.sumario_consulta.parametros.cod_ref,
            id: "2",
            titulo: "Bloco 02",
            descricao: "Valores estimados",
            imagem: "/img/modelos/icone-bloco-score.png",
          };
          this.listaBlocos.push(blocoModelo);
        }
      }

      if (this.consultasSelecionadas.length > 0) {
        const bloco1Selecionado = this.consultasSelecionadas.find(
          (objeto) => objeto.Valor === "BOXSUPPF001"
        );
        const bloco5Selecionado = this.consultasSelecionadas.find(
          (objeto) => objeto.Valor === "BOXSUPPF005"
        );
        const bloco1PJ = this.consultasSelecionadas.find(
          (objeto) => objeto.Valor === "BOXSUPPJ001"
        );
        const bloco2PJ = this.consultasSelecionadas.find(
          (objeto) => objeto.Valor === "BOXSUPPJ002"
        );
        const blocoKYC = this.consultasSelecionadas.find(
          (objeto) => objeto.Valor === "BOXKYC"
        );
        const blocoAlerta = this.consultasSelecionadas.find(
          (objeto) => objeto.Valor === "BOXSUPPJ009"
        );

        if (bloco1Selecionado) {
          const bloco1 = {
            box: bloco1Selecionado.Valor,
            id: "1",
            titulo: "Bloco 01",
            descricao: "Dados Cadastrais",
            imagem: "/img/superbox/bloco-dados-cadastrais.png",
          };
          this.listaBlocos.push(bloco1);
        }

        if (bloco5Selecionado) {
          const bloco5 = {
            box: bloco5Selecionado.Valor,
            id: "3",
            titulo: "Bloco 03",
            descricao: "Mapa Geral de Riscos",
            imagem: "/img/superbox/bloco-mapa-riscos.png",
          };
          this.listaBlocos.push(bloco5);
        }

        if (bloco1PJ) {
          const bloco1 = {
            box: "BOXSUPPJ001",
            id: "1",
            titulo: "Bloco 01",
            descricao: "Informações Cadastrais",
            imagem: "/img/superbox/bloco-dados-cadastrais.png",
          };
          this.listaBlocos.push(bloco1);
        }

        if (bloco2PJ) {
          const bloco3 = {
            box: "BOXSUPPJ001",
            id: "3",
            titulo: "Bloco 03",
            descricao: "Detalhamentos Cadastrais da Matriz",
            imagem: "/img/superbox/bloco-conexoes-empresas.png",
          };
          this.listaBlocos.push(bloco3);
        }

        if (blocoKYC) {
          const bloco3 = {
            box: "BOXKYC001",
            id: "4",
            titulo: "Bloco 04",
            descricao: "KYC & Due Diligence",
            imagem: "/img/modelos/icone-bloco-score.png",
          };
          this.listaBlocos.push(bloco3);
        }

        if (blocoAlerta) {
          const bloco3 = {
            box: "BOXSUPPJ009",
            id: "5",
            titulo: "Bloco 05",
            descricao: "Alertas Gerais",
            imagem: "/img/superbox/bloco-alertas-gerais.png",
          };
          this.listaBlocos.push(bloco3);
        }
      }
    },

    abreBlocos() {
      if (this.resultadoModelo.atributos != undefined) {
        if (this.resultadoModelo.atributos.score != undefined) {
          this.$refs[`blocopfScore`].abreEsteBloco();
        }

        if (
          this.resultadoModelo.atributos.renda_presumida != undefined ||
          this.resultadoModelo.atributos.faturamento_presumido != undefined
        ) {
          this.$refs[`blocopfRenda`].abreEsteBloco();
        }
      }

      if (this.resultadoConsultaAdd.BOXSUPPF001 != undefined) {
        var nome =
          this.resultadoConsultaAdd.BOXSUPPF001.atributos.bk_101_001_nome;
        var cpf = mascaras.cpf(
          this.resultadoConsultaAdd.BOXSUPPF001.sumario_consulta.parametros.cpf
        );

        this.nomeCompleto = nome;
        this.valorCPF = cpf;

        if (this.ocultarDados) {
          let palavras = nome.split(" ");

          if (palavras.length > 1) {
            for (let i = 1; i < palavras.length; i++) {
              palavras[i] = palavras[i].replace(/\S/g, "*");
            }
          }

          // this.valorCPF = cpf.replace(
          //   /(\d{3})\.(\d{3})\.(\d{3})-(\d{2})/,
          //   "***.$2.$3-**"
          // );

          this.nomeCompleto = palavras.join(" ");
        }

        this.$refs[`blocopfDadosCadastrais`].abreEsteBloco();
      }

      if (this.resultadoConsultaAdd.BOXSUPPF005 != undefined) {
        this.$refs[`blocopfMapaRiscos`].abreEsteBloco();
      }

      if (this.resultadoConsultaAdd.BOXSUPPJ001 != undefined) {
        this.$refs[`blocopjInfo1`].abreEsteBloco();
      }

      if (this.resultadoConsultaAdd.BOXSUPPJ002 != undefined) {
        this.$refs[`blocopjInfo2`].abreEsteBloco();
      }
      if (
        this.resultadoConsultaAdd.BOXKYC001 != undefined ||
        this.resultadoConsultaAdd.BOXKYC002 != undefined ||
        this.resultadoConsultaAdd.BOXKYC003 != undefined ||
        this.resultadoConsultaAdd.BOXKYC004 != undefined
      ) {
        this.$refs[`blocopjKyc`].abreEsteBloco();
      }

      if (
        this.consultasSelecionadas.find(
          (objeto) => objeto.Valor === "BOXSUPPJ009"
        )
      ) {
        this.$refs[`blocopjAlertas`].abreEsteBloco();
      }
    },

    recebePrefTooltips(pref) {
      this.exibirTootipsConsultas = pref;
    },

    ...mapActions(userPreferenceStore, [
      "syncPreferenciaOcultarDados",
      "syncPreferenciaConsultasTooltips",
      "syncPreferenciaTipoDownload",
    ]),
  },

  computed: {
    ...mapWritableState(userPreferenceStore, {
      ocultarDados: "ocultarDados",
      exibirTootipsConsultas: "exibirTootipsConsultas",
      tipoDownloadConsulta: "tipoDownloadConsulta",
    }),

    ...mapWritableState(produtosStore, {
      codigo_agrupador: "codigo_agrupador",
    }),

    ...mapWritableState(dadosConsultaAddStore, {
      consultasTrilha: "consultasTrilha",
      trilhaSelecionada: "trilhaSelecionada",
    }),
  },
};
</script>

<style lang="scss" scoped>
.container-mod {
  width: 100%;
  margin: 0px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nao_encontrado {
  margin: 30px 0;
  grid-column: 1/4;
  font-size: 1.5rem;
  font-weight: 300;

  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.sad {
  font-size: 3rem;
}

.header-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 960px;
}
.container-header {
  display: flex;
  width: 100%;
  height: 105px;
  max-width: 960px;
  background-image: url(/img/modelos/Topo_Modelos.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  margin: 10px 0 -20px 0;
}

.header-text {
  display: flex;
  flex-direction: column;
  color: #4356a5;
  width: 80%;
  align-items: flex-start;
  margin: 0 20px 0 25%;
  line-height: 21px;
  gap: 5px;

  .text-top {
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
  }

  .text-desc {
    font-weight: 600;
    font-size: 20px;
    line-height: 21px;
  }
}

.header-icons {
  display: flex;
  width: 35%;
  gap: 10px;
  margin: 0 10px;
  justify-content: flex-end;

  .icon-btn {
    width: 40px;
    height: 40px;
    // border: 2px solid #4356a5;
    border: 2px solid #1da0f2;
    background-color: #1da0f2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0 0 0 1px;
    transition: 0.4s;

    &:hover {
      transform: scale(1.05);
      background-color: transparent;
      border: 2px solid #4356a5;
    }
    &:active {
      transform: scale(0.9);
    }
  }

  .icon-header {
    width: 20px;
  }
}

.buttons-box {
  display: flex;
  width: 100%;
  max-width: 960px;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.icon-param {
  height: 20px;
  margin: 10px 0 0 10px;
  cursor: pointer;
  transition: 0.5s;
  position: relative;
  top: 25px;
  z-index: 10;

  &.close {
    transform: rotateY(180deg);
  }

  &:hover {
    transform: scale(1.05);
    &.close {
      transform: rotateY(180deg);
    }
  }
  &:active {
    transform: scale(0.9);
    &.close {
      transform: rotateY(180deg);
    }
  }
}

.loading {
  position: absolute;
  top: 100px;
  right: 45%;
}

.button-disabled {
  border: 2px solid #8c9296 !important;
  background-color: #a0a0a031;
  color: #ffffff;
  cursor: not-allowed;
  pointer-events: none;
  border: none;
}

.spinnerBG {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 125%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99;
}

.spinner {
  position: fixed;
  top: 40%;
  left: 52%;
  transform: scale(1.1);
  z-index: 10;
}
</style>
