<template>
  <div class="container-campos" v-if="parametrosUtilizados.length > 0">
    <div class="campos">
      <div
        v-for="parametro in parametrosUtilizados"
        :key="parametro.nome_api"
        style="width: 100%"
        class="campo-input"
      >
        <md-field class="box-inputs no-counter" :class="parametro.Classe">
          <!-- {{ parametro.Valor }} -->
          <label>
            {{ parametro.nome_amigavel }}
            <span v-if="parametro.Tipo == 'Data'"> (dd/mm/aaaa) </span>
            <!-- ({{ parametro.Tipo }}) -->
          </label>

          <md-input
            v-if="
              parametro.dominio == undefined &&
              parametro.tipo_input != 'numero-dominio' &&
              parametro.tipo_input != 'selecao' &&
              parametro.Tipo != 'Inteiro'
            "
            v-model="parametro.Valor"
            :required="parametro.Obrigatorio"
            :type="'text'"
            :placeholder="''"
            :maxlength="parametro.tamanho"
            @input="aplicarMascara(parametro)"
            @keyup.enter="decideConsulta()"
            @keydown="filtrarTeclado($event, parametro)"
          />
          <button class="date-btn" @click="abreEscolhaData(parametro)">
            <span
              class="material-icons-outlined calendar"
              v-if="
                (parametro.tipo_input == 'data' || parametro.Tipo == 'Data') &&
                (liberaDatePicker[parametro.nome_api] == false ||
                  liberaDatePicker[parametro.nome_api] == undefined)
              "
            >
              calendar_month
            </span>
            <span
              class="material-icons-outlined calendar"
              v-if="
                (parametro.tipo_input == 'data' || parametro.Tipo == 'Data') &&
                liberaDatePicker[parametro.nome_api] == true
              "
            >
              event_busy
            </span>
          </button>
          <b-calendar
            v-if="
              (parametro.tipo_input == 'data' || parametro.Tipo == 'Data') &&
              liberaDatePicker[parametro.nome_api] == true
            "
            :max="new Date()"
            class="box-calendar"
            v-model="parametro.ValorDatePicker"
            @input="selecionaData($event, parametro)"
            locale="pt-BR"
            :hide-header="true"
            nav-button-variant="primary"
            label-help="Selecione uma data"
            :initial-date="
              parametro.Valor != undefined || parametro.Valor != ''
                ? formatDateYMD(parametro.Valor)
                : formatDateYMD(new Date())
            "
          ></b-calendar>
          <md-input
            v-if="
              parametro.dominio == undefined &&
              parametro.tipo_input != 'numero-dominio' &&
              parametro.Tipo == 'Inteiro'
            "
            v-model="parametro.Valor"
            :required="parametro.Obrigatorio"
            :type="'number'"
            :placeholder="''"
            :maxlength="parametro.tamanho"
            @input="aplicarMascara(parametro)"
            @keyup.enter="decideConsulta()"
            @keydown="filtrarTeclado($event, parametro)"
          />
          <md-input
            class="no-counter"
            v-if="
              parametro.dominio != undefined &&
              parametro.tipo_input == 'numero-dominio'
            "
            v-model="parametro.Valor"
            :required="parametro.Obrigatorio"
            :type="'number'"
            :maxlength="parametro.tamanho"
            @input="filtrarDominio(parametro)"
            @keyup.enter="decideConsulta()"
            @keydown="filtrarTeclado($event, parametro)"
          />
          <span
            v-if="
              parametro.dominio != undefined &&
              parametro.tipo_input == 'numero-dominio'
            "
            class="placeholder-text"
          >
            {{ parametro.placeholderText }}
          </span>

          <md-select
            v-if="
              parametro.tipo_input == 'selecao' && parametro.dominio.length < 5
            "
            name="select-modelos"
            id="select-modelos"
            v-model="parametro.Valor"
            class="box-select"
          >
            <span v-for="(opcao, index) in parametro.dominio" :key="index">
              <md-option
                :value="opcao.valor"
                :id="'opcao-' + index"
                style="white-space: wrap"
              >
                {{ opcao.descricao }}
              </md-option>
            </span>
          </md-select>
          <md-select
            v-if="
              parametro.tipo_input == 'selecao' && parametro.dominio.length >= 5
            "
            name="select-modelos"
            id="select-modelos"
            v-model="parametro.Valor"
            :placeholder="parametro.nome_amigavel"
            @click="clearSearch"
            ref="meuSelect"
            select-clear
          >
            <div class="textSearch">
              <md-input
                v-model="searchSelect"
                class="textSearchInput"
                placeholder="Pesquise um valor"
              >
              </md-input>
            </div>
            <span
              v-for="(opcao, index) in optionsListResposta(parametro.dominio)"
              :key="index"
            >
              <md-option
                :value="opcao.valor"
                :id="'opcao-' + index"
                style="white-space: wrap"
              >
                {{ opcao.descricao }}
              </md-option>
            </span>
          </md-select>
        </md-field>
      </div>
    </div>
  </div>
</template>

<script>
import { mapWritableState, mapActions } from "pinia";
import { userPreferenceStore } from "@/stores/userPreference";
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { validadorCpf, validadorCnpj, validadorDataPtbr } from "@/validadores";

export default {
  name: "SelecaoConsAddPreenchimento",

  props: {
    parametrosUtilizados: Array,
  },

  data() {
    return {
      resultados: [],
      searchSelect: "",
      liberaDatePicker: {},
    };
  },

  components: {},

  watch: {},

  methods: {
    selecionaData(context, parametro) {
      const dataBR = context.split("-").reverse().join("/");
      parametro.Valor = dataBR;

      this.liberaDatePicker[parametro.nome_api] = false;
      this.selecionaClasseInput(parametro);
    },

    filtrarDominio(parametro) {
      const valorDigitado = parametro.Valor;
      const itemDominio = parametro.dominio.find(
        (item) => item.valor === valorDigitado
      );
      if (parametro.Valor != "" && parametro.Valor != undefined) {
        if (itemDominio) {
          parametro.placeholderText = `${itemDominio.filial} - ${itemDominio.regiao}`;
        } else {
          parametro.placeholderText = "Filial não encontrada";
        }
      } else {
        parametro.placeholderText = "";
      }
      this.selecionaClasseInput(parametro);
    },

    optionsListResposta(opcoes) {
      if (this.searchSelect.trim().length > 0) {
        return opcoes.filter((opcao) =>
          opcao.descricao
            .toLowerCase()
            .includes(this.searchSelect.trim().toLowerCase())
        );
      } else {
        return opcoes;
      }
    },

    clearSearch() {
      this.searchSelect = "";
    },

    decideConsulta() {
      this.$emit("consultar");
    },

    aplicarMascara(parametro) {
      let formatted = "";
      let index = 0;

      if (parametro.Tipo == "Data") {
        parametro.mascara = "##/##/####";
        parametro.tamanho = 10;
      }

      if (parametro.Valor != undefined) {
        if (parametro.Valor.trim() !== "") {
          if (
            parametro.Tipo === "Cpf" ||
            parametro.Tipo == "Cnpj" ||
            parametro.Tipo == "Data" ||
            parametro.Tipo == "Cep"
          ) {
            if (parametro.mascara) {
              const mask = parametro.mascara;
              const inputValue = parametro.Valor.replace(/[^0-9]+/g, "");
              let result = inputValue.slice(0, parametro.tamanho);

              for (let i = 0; i < mask.length && index < result.length; i++) {
                const maskChar = mask[i];
                if (maskChar === "#") {
                  formatted += result[index] || "";
                  index++;
                } else {
                  formatted += maskChar;
                }
              }
            } else {
              formatted = parametro.Valor;
            }
          } else if (parametro.Tipo === "Texto") {
            formatted = parametro.Valor;
          } else if (parametro.Tipo === "Inteiro") {
            if (parametro.tipo_input == "numero") {
              formatted = parametro.Valor.replace(/[^\d,]/g, "");
            } else if (parametro.tipo_input == "numero-dominio") {
              formatted = parametro.Valor.replace(/[^\d,]/g, "");
            } else {
              formatted = parametro.Valor.replace(/[^\d,]/g, "");
            }
          } else if (parametro.Tipo === "Decimal") {
            if (parametro.tipo_input == "numero") {
              formatted = parametro.Valor.replace(/[^\d,]/g, "");
            } else if (parametro.tipo_input == "valor") {
              const valorNumerico =
                parseFloat(parametro.Valor.replace(/[^\d]/g, "")) / 100;

              const valorFormatado = valorNumerico.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });

              formatted = valorFormatado;
            } else {
              formatted = parametro.Valor.replace(/[^\d,]/g, "");
            }
          } else {
            formatted = parametro.Valor;
          }
        }
      }
      this.$set(parametro, "Valor", formatted);

      this.selecionaClasseInput(parametro);

      return parametro;
    },

    selecionaClasseInput(parametro) {
      if (parametro.Valor != undefined) {
        if (parametro.Valor.trim() !== "") {
          if (
            parametro.Tipo === "Cpf" ||
            parametro.Tipo == "Cnpj" ||
            parametro.Tipo == "Data" ||
            parametro.Tipo == "Cep"
          ) {
            if (parametro.mascara) {
              if (parametro.Valor.length < parametro.tamanho) {
                parametro.Classe = "md-edit";
              } else if (parametro.Valor.length === parametro.tamanho) {
                if (
                  (parametro.nome_api == "cpf" &&
                    !validadorCpf.valida(parametro.Valor)) ||
                  (parametro.nome_api == "cnpj" &&
                    !validadorCnpj.valida(parametro.Valor)) ||
                  (parametro.Tipo == "Data" &&
                    (!validadorDataPtbr.valida(parametro.Valor) ||
                      new Date(this.formatDateYMD(parametro.Valor)) >
                        new Date()))
                ) {
                  parametro.Classe = "md-error";
                } else {
                  parametro.Classe = "md-valid";
                }
              }
            } else {
              if (
                (parametro.Tipo === "Cpf" &&
                  !validadorCpf.valida(parametro.Valor)) ||
                (parametro.Tipo == "Cnpj" &&
                  !validadorCnpj.valida(parametro.Valor)) ||
                (parametro.Tipo == "Data" &&
                  !validadorDataPtbr.valida(parametro.Valor))
              ) {
                parametro.Classe = "md-error";
              } else {
                parametro.Classe = "md-valid";
              }
            }
          } else if (parametro.Tipo === "Texto") {
            if (parametro.tamanho) {
              if (parametro.Valor.length < parametro.tamanho) {
                parametro.Classe = "md-edit";
              } else if (parametro.Valor.length > parametro.tamanho) {
                parametro.Classe = "md-error";
              } else {
                parametro.Classe = "md-valid";
              }
            } else {
              parametro.Classe = "md-valid";
            }
          } else if (parametro.Tipo === "Inteiro") {
            if (parametro.tipo_input == "numero") {
              if (/^\d+$/.test(parametro.Valor)) {
                parametro.Classe = "md-valid";
              } else {
                parametro.Classe = "md-error";
              }
            } else if (parametro.tipo_input == "numero-dominio") {
              if (/^\d+$/.test(parametro.Valor)) {
                parametro.Classe = "md-valid";
              } else {
                parametro.Classe = "md-error";
              }
            } else {
              if (/^\d+$/.test(parametro.Valor)) {
                parametro.Classe = "md-valid";
              } else {
                parametro.Classe = "md-error";
              }
            }
          } else if (parametro.Tipo === "Decimal") {
            if (parametro.tipo_input == "numero") {
              if (/^\d+(,\d*)?$/.test(parametro.Valor)) {
                parametro.Classe = "md-valid";
              } else {
                parametro.Classe = "md-error";
              }
            } else if (parametro.tipo_input == "valor") {
              var valorSemEspaco = parametro.Valor.replace(/\s+/g, "");
              if (
                /^(R\$)?[0-9]+([,.][0-9]{3})*([,.][0-9]{2})?$/.test(
                  valorSemEspaco
                )
              ) {
                parametro.Classe = "md-valid";
              } else {
                parametro.Classe = "md-error";
              }
            } else {
              if (/^\d+(,\d*)?$/.test(parametro.Valor)) {
                parametro.Classe = "md-valid";
              } else {
                parametro.Classe = "md-error";
              }
            }
          } else {
            parametro.Classe = "md-valid";
          }
        } else {
          parametro.Classe = undefined;
        }
      }
    },

    abreEscolhaData(parametro) {
      if (this.liberaDatePicker[parametro.nome_api] == true) {
        this.$set(this.liberaDatePicker, parametro.nome_api, false);
      } else {
        this.$set(this.liberaDatePicker, parametro.nome_api, true);
      }
    },

    filtrarTeclado(event, parametro) {
      const keyCode = event.key;
      const ctrlKey = event.ctrlKey;

      if (parametro.Tipo == "Inteiro") {
        if (/^[0-9]+$/.test(keyCode) || keyCode.length > 2 || ctrlKey) {
          return;
        } else {
          event.preventDefault();
        }
      }

      if (parametro.Tipo == "Decimal") {
        if (
          /^[0-9]+$/.test(keyCode) ||
          keyCode.length > 2 ||
          (/^(\d+|\,)$/.test(keyCode) && !parametro.Valor.includes(",")) ||
          ctrlKey
        ) {
          return;
        } else {
          event.preventDefault();
        }
      }
      if (parametro.tipo_input == "valor") {
        if (/^[0-9]+$/.test(keyCode) || keyCode.length > 2 || ctrlKey) {
          return;
        } else {
          event.preventDefault();
        }
      }
    },

    formatDateYMD(input) {
      if (input instanceof Date) {
        const year = input.getFullYear();
        const month = String(input.getMonth() + 1).padStart(2, "0");
        const day = String(input.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      } else if (typeof input === "string") {
        const parts = input.split("/");
        if (parts.length === 3) {
          const year = parts[2];
          const month = String(parts[1]).padStart(2, "0");
          const day = String(parts[0]).padStart(2, "0");
          return `${year}-${month}-${day}`;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },

    verificaDocumento(str) {
      const cleanedStr = str.replace(/[^\d]/g, "");
      if (cleanedStr.length === 11) {
        return "CPF";
      }
      if (cleanedStr.length === 14 && str.includes("/") && str.includes("-")) {
        return "CNPJ";
      }
      if (cleanedStr.length === 8 && str.includes("-")) {
        return "CEP";
      }
      return "Descrição";
    },

    ...mapActions(dadosConsultaAddStore, ["resetConsutaAdd"]),
  },

  computed: {
    ...mapWritableState(userPreferenceStore, {
      usuarioLogado: "usuarioLogado",
    }),
  },

  async mounted() {},
};
</script>

<style lang="scss">
.no-counter {
  .md-count {
    display: none !important;
  }
}
</style>

<style scoped lang="scss">
.container-campos {
  width: 100%;
  height: 100%;
}

.campos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 0 20px;
}

.campo-input {
  width: 100%;
  display: flex;
  justify-content: center;
}
.box-inputs {
  width: 300px;
  // width: 100%;
}

.selecao {
  gap: 10px;
  width: 20vw;
  line-height: 20px;

  .titulo {
    font-size: 20px;
    font-weight: 600;
    margin: 20px 0;
  }
  .valor {
    font-size: 16px;
    font-weight: 400;
    display: flex;
    gap: 10px;
  }
  .descricao {
    font-size: 12px;
    font-weight: 300;
  }
}

.item {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.textSearch {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}

.textSearchInput {
  display: flex;
  width: 95%;
  height: 35px;
  border-width: 0px 0px 0.1rem 0px;
  font-size: small;
}

.placeholder-text {
  position: absolute;
  font-size: 12px;
  right: 30px;
  top: 10px;
  color: gray;
}

.date-btn {
  border: 1px solid transparent;
  background: transparent;
  cursor: pointer;
  margin: 0;
  border-radius: 25%;
  padding: 5px 0 0 0;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
}
.calendar {
  color: grey;
  cursor: pointer;
  margin: 5px;
}

.box-calendar {
  position: absolute;
  left: 100%;
  z-index: 11;
  background-color: white;
  padding: 5px;
}
</style>

<style>
.md-field.md-has-password .md-toggle-password {
  background-color: transparent !important;
  box-shadow: none;
}
</style>
