<template>
  <div class="trilha-container">
    <div class="box-icone">
      <div class="box-up">{{ consultasTrilha.length }}</div>
      <div class="box-down">
        <span
          class="material-symbols-outlined icon-botao"
          @click="ativaTrilha()"
        >
          recent_actors
        </span>
      </div>
    </div>
    <transition name="slide-grow">
      <div
        class="menu-container"
        v-if="abreTrilha"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
      >
        <div class="titulo">
          <span class="material-symbols-outlined icon-titulo">
            recent_actors
          </span>
          Trilha de Consultas
        </div>
        <div class="carrossel_container">
          <Flicking
            class="carrossel_box"
            :options="flickingOptions"
            :plugins="flickingPlugins"
            ref="flicking"
          >
            <div
              v-for="(card, index) in consultasTrilhaCarrossel"
              :key="index"
              class="slide-card"
            >
              <div
                class="card"
                :class="{
                  selecionado:
                    card &&
                    trilhaSelecionada.cod_agrupador == card.cod_agrupador,
                }"
                v-if="card"
                @click="selecionaTrilha(card)"
                :id="'card-' + card.cod_agrupador"
              >
                <span
                  class="material-symbols-outlined icon-tipo"
                  v-if="card.tipo_pessoa == 'PF'"
                >
                  face
                </span>
                <span
                  class="material-symbols-outlined icon-tipo"
                  v-if="card.tipo_pessoa == 'PJ'"
                >
                  business
                </span>
                <span class="texto ref">{{ card.cod_agrupador }}</span>
              </div>
              <div class="card vazio" :id="'vazio-' + index" v-else>
                <span class="material-icons icon-vazio"> block </span>
                <span class="texto vazio">-</span>
              </div>
              <div class="card-texto">
                <span v-if="index < 9">0</span>
                <span> {{ index + 1 }} </span>
                <span
                  v-if="card && index > 0"
                  class="material-icons icon-origem"
                >
                  arrow_circle_left
                </span>
                <span v-if="card && card.origem < 9 && card.origem > 0">0</span>

                <span v-if="card && card.origem > 0"> {{ card.origem }} </span>
              </div>
              <b-tooltip
                v-if="card"
                :target="'card-' + card.cod_agrupador"
                triggers="hover"
                placement="top"
                variant="dark"
                class="tooltipTexts"
                boundary="viewport"
              >
                <div class="conteudo-tooltip">
                  <span class="texto">
                    {{ card.consulta }}
                  </span>
                  <template v-for="(valor, chave) in card.parametros">
                    <span
                      class="texto"
                      v-if="valor != '' && (chave == 'cpf' || chave == 'cnpj')"
                      :key="chave"
                    >
                      {{ chave.toUpperCase() }}:
                      {{ formataDocumento(chave, valor) }}
                    </span>
                  </template>
                  <span class="texto" v-if="card.modeloSelecionado">
                    {{ card.modeloSelecionado.Valor }}
                  </span>
                  <span
                    class="texto"
                    v-if="card.consultasSelecionadas.length > 0"
                  >
                    <span
                      class="texto consultas"
                      v-for="(consulta, index) in card.consultasSelecionadas"
                      :key="index"
                    >
                      {{ consulta.Nome }}
                    </span>
                  </span>
                </div>
              </b-tooltip>
            </div>
            <span
              slot="viewport"
              class="flicking-arrow-prev"
              v-show="consultasTrilhaCarrossel.length > 7"
            >
            </span>
            <span
              slot="viewport"
              v-show="consultasTrilhaCarrossel.length > 7"
              class="flicking-arrow-next"
            >
            </span>
          </Flicking>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { mapActions, mapWritableState } from "pinia";

import { Flicking } from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";
import { Pagination, Arrow } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/pagination.css";
import "@egjs/flicking-plugins/dist/arrow.css";

export default {
  name: "TrilhaConsultas",
  components: {
    Flicking: Flicking,
  },

  data() {
    return {
      abreTrilha: false,
      timeoutId: null,
      isHovered: false,
      consultasTrilhaCarrossel: new Array(10).fill(null),
    };
  },

  props: {},

  watch: {
    consultasTrilha: {
      handler() {
        this.atualizarCarrossel();
      },
      deep: true,
    },

    trilhaSelecionada: {
      handler() {
        this.iniciarFechamento();
      },
      deep: true,
    },
  },

  methods: {
    ativaTrilha() {
      this.abreTrilha = !this.abreTrilha;
      if (this.abreTrilha) {
        this.iniciarFechamento();
      } else {
        this.cancelarFechamento();
      }
    },

    iniciarFechamento() {
      this.cancelarFechamento();
      this.timeoutId = setTimeout(() => {
        if (!this.isHovered) {
          this.abreTrilha = false;
        }
      }, 2000);
    },
    cancelarFechamento() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }
    },
    handleMouseEnter() {
      this.isHovered = true;
      this.cancelarFechamento();
    },
    handleMouseLeave() {
      this.isHovered = false;
      this.iniciarFechamento();
    },

    atualizarCarrossel() {
      if (this.consultasTrilha.length > this.consultasTrilhaCarrossel.length) {
        this.consultasTrilhaCarrossel = [
          ...this.consultasTrilhaCarrossel,
          ...new Array(
            this.consultasTrilha.length - this.consultasTrilhaCarrossel.length
          ).fill(null),
        ];
      }
      this.consultasTrilhaCarrossel = this.consultasTrilhaCarrossel.map(
        (item, index) =>
          this.consultasTrilha[index] ? this.consultasTrilha[index] : item
      );

      if (this.consultasTrilha.length > 1) {
        this.abreTrilha = true;
        this.liberaSetas = true;
      }
    },

    selecionaTrilha(consulta) {
      if (
        consulta &&
        consulta.cod_agrupador != this.trilhaSelecionada.cod_agrupador
      ) {
        this.trilhaSelecionada = consulta;
        this.tipoConsultaSelecionada = consulta.consulta;
        this.atualizaNovaConsultaMod();
        this.abreSelecaoConsultaCiclica = false;
        setTimeout(() => {
          // this.abreTrilha = false;
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }, 1500);
      }
    },

    formataDocumento(chave, documento) {
      if (chave == "cpf") {
        return this.formatarCPF(documento);
      } else if (chave == "cnpj") {
        return this.formatarCNPJ(documento);
      } else {
        return documento;
      }
    },

    formatarCPF(cpf) {
      if (cpf && cpf != "-") {
        cpf = cpf.replace(/\D/g, "");

        cpf = cpf.replace(/^(\d{3})(\d)/, "$1.$2");
        cpf = cpf.replace(/^(\d{3})\.(\d{3})(\d)/, "$1.$2.$3");
        cpf = cpf.replace(/\.(\d{3})(\d)/, ".$1-$2");

        if (this.ocultarDados) {
          return cpf.replace(
            /(\d{3})\.(\d{3})\.(\d{3})-(\d{2})/,
            "***.$2.$3-**"
          );
        } else {
          return cpf;
        }
      }
      return cpf;
    },

    formatarCNPJ(cnpj) {
      if (cnpj != null && cnpj != "-") {
        cnpj = cnpj.replace(/\D/g, "");
        cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2");
        cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
        cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
        cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");
        if (this.ocultarDados) {
          cnpj = cnpj.replace(
            /(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})\-(\d{2})/,
            (match, p1, p2, p3, p4, p5) => `${p1}.***.***/****-${p5}`
          );
        }
      }

      return cnpj;
    },

    ...mapActions(dadosConsultaAddStore, ["atualizaNovaConsultaMod"]),
  },

  computed: {
    flickingOptions() {
      return {
        circular: true,
        align: "center",
        changeOnHold: true,
        defaultIndex:
          this.trilhaSelecionada.id < 4 ? 3 : this.trilhaSelecionada.id - 1,
        disableInput: this.consultasTrilhaCarrossel.length <= 7,
        moveByViewportSize: true,
      };
    },

    flickingPlugins() {
      return [new Arrow({ moveByViewportSize: false })];
    },

    ...mapWritableState(dadosConsultaAddStore, {
      consultasTrilha: "consultasTrilha",
      tipoConsultaSelecionada: "tipoConsultaSelecionada",
      trilhaSelecionada: "trilhaSelecionada",
      abreSelecaoConsultaCiclica: "abreSelecaoConsultaCiclica",
    }),
  },

  mounted() {
    this.trilhaSelecionada = this.consultasTrilha[0];
    this.atualizarCarrossel();
  },
};
</script>

<style lang="scss" scoped>
.menu-container {
  position: fixed;
  z-index: 10;
  height: 150px;
  width: 705px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;
  background-color: white;
  border-radius: 10px;
  padding: 15px 25px 10px 25px;
  transition: all 0.5s ease;
  right: calc(50% - 490px);
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.65);
}

.titulo {
  width: 64%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4356a5;
  border-radius: 5px;
  height: 30px;
  color: white;
  line-height: 30px;
}

.icon-titulo {
  margin-right: 5px;
}
.icon-vazio {
  color: #d9d9d9;
  font-size: 34px;
}

.texto {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;

  &.vazio {
    font-size: 18px;
  }
  &.max {
    font-size: 16px;
    line-height: 16px;
    text-align: center;
  }

  &.consultas {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.carrossel_container {
  width: 100%;
  height: 310px;
  padding: 0px 0 0 0;
  overflow: hidden;
  display: flex;
}

.carrossel_box {
  width: 100%;
  margin: 0 1px;
  display: flex;
}

.slide-card {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.card {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  place-items: start;
  transition: 0.5s;
  height: 60px;
  width: 60px;
  margin: 10px 15px 5px 15px;
  border: 1px solid #0092f6;
  align-items: center;
  cursor: pointer;
  color: #0092f6;

  &.selecionado {
    background-color: #0092f6;
    color: white;
  }

  &.vazio {
    background-color: #f2f2f2;
    border: 1px solid #d9d9d9;
    color: #d9d9d9;
    font-size: 26px;
  }

  &.max {
    border: 1px solid #d9d9d9;
    color: #d9d9d9;
    pointer-events: none;
    cursor: default;
    &:hover {
      transform: scale(1);
    }
  }

  &:hover {
    transform: scale(1.1);
  }
}

.card-texto {
  font-size: 12px;
  text-align: center;
  margin: 0px 12.5px 0px 12.5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-tipo {
  font-size: 34px;
}

.icon-origem {
  font-size: 18px;
  color: #0092f6;
  margin: 0 3px;
}

.box-icone {
  background-image: url(/img/icone-trilha-consultas.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: transparent;
  position: fixed;
  right: 15px;
  bottom: 64px;
  width: 40px;
  height: 70px;
  z-index: 999;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  color: #0092f6;
  cursor: default;
}

.icon-botao {
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
}

.box-up {
  font-size: 13px;
  font-weight: 600;
  padding-bottom: 5px;
}

.conteudo-tooltip {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.flicking-arrow-prev,
.flicking-arrow-next {
  background-color: transparent;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  top: 40px;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  transform: translateY(-50%);
}

.flicking-arrow-prev {
  left: 0px;
}

.flicking-arrow-next {
  right: 0px;
}

.flicking-arrow-prev::before,
.flicking-arrow-prev::after,
.flicking-arrow-next::before,
.flicking-arrow-next::after {
  background-color: #bfbdbd;
  width: 12px;
  height: 4px;
  position: absolute;
  content: "";
}

.flicking-arrow-prev::before {
  transform: rotate(-45deg);
  transform-origin: 0% 50%;
  left: 8px;
  top: 50%;
}

.flicking-arrow-prev::after {
  left: 8px;
  top: calc(52% - 4px);
  transform: rotate(45deg);
  transform-origin: 0% 50%;
}

.flicking-arrow-next::before {
  left: 6px;
  top: 50%;
  transform: rotate(45deg);
  transform-origin: 100% 50%;
}

.flicking-arrow-next::after {
  left: 6px;
  top: calc(52% - 4px);
  transform: rotate(-45deg);
  transform-origin: 100% 50%;
}

.slide-grow-enter-active,
.slide-grow-leave-active {
  transition: all 1s ease;
}

.slide-grow-enter,
.slide-grow-leave-to {
  width: 0;
  right: 15px;
  bottom: 20px;
  opacity: 0;
}

.slide-grow-enter-to {
  width: 705px;
  opacity: 1;
}
</style>
