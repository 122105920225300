<template>
  <div class="menu-container">
    <span
      class="material-symbols-outlined close-icon"
      @click="fecharConsultaAdd()"
    >
      close
    </span>
    <div class="content">
      <div class="titulo">Nova consulta</div>
      <div class="box-dados">
        <div class="doc">
          <label class="label" for="cep-input"> Documento selecionado </label>
          <input
            class="search disabled"
            type="text"
            v-model="documentoFormatado"
            id="cpf-input"
          />
        </div>

        <div class="doc">
          <label class="label" v-if="paramentoCep != ''" for="cep-input">
            CEP (Opcional)
          </label>
          <input
            class="search"
            type="text"
            v-model="paramentoCep"
            placeholder="CEP (Opcional)"
            id="cep-input"
            name="cep-input"
            @input="aplicaMascara('cep', paramentoCep)"
            maxlength="9"
          />
        </div>
        <!-- <div
          class="doc"
          v-if="pagina == 1 && usuarioLogado.Empresa.includes('StepWise')"
        >
          <label class="label" v-if="paramentoDataRef != ''" for="cep-input">
            Data de referência (dd/mm/yyyy)
          </label>
          <input
            class="search"
            type="text"
            v-model="paramentoDataRef"
            placeholder="Data_ref (dd/mm/yyyy)"
            id="cep-input"
            name="cep-input"
            @input="aplicaMascara('data_ref', paramentoDataRef)"
            maxlength="10"
          />
        </div> -->
      </div>
      <div class="consulta-container" v-if="pagina == 1">
        <div
          class="consulta-itens"
          v-for="(consulta, index) in listaTiposConsultaFiltrada"
          :key="index"
          :id="'box-consulta-adicional' + index"
        >
          <div
            class="box-consulta"
            :class="{
              selecionado: tipoConsulta == consulta.valor,
              travado:
                consulta.consultado.includes(consulta.valor) &&
                consulta.valor == 'Superbox',
            }"
            @click="tipoConsulta = consulta.valor"
          >
            <div class="header-text">
              <span class="text-top"> {{ consulta.nome }} </span>
            </div>
          </div>
          <b-tooltip
            v-if="
              consulta.consultado.includes(consulta.valor) &&
              consulta.valor == 'Superbox'
            "
            :target="'box-consulta-adicional' + index"
            triggers="hover"
            placement="top"
            variant="dark"
            class="tooltipTexts"
            boundary="viewport"
          >
            Produto já consultado
          </b-tooltip>
          <div
            class="opcoes-modelo"
            v-if="tipoConsulta == 'Modelos' && consulta.valor == 'Modelos'"
          >
            <md-field
              class="campo-selecao"
              :class="{ grande: listaModelosFiltrados.length == 0 }"
            >
              <md-select
                :placeholder="
                  listaModelosFiltrados.length == 0
                    ? 'Não existem modelos associados ao seu usuário'
                    : 'Selecione o modelo que deseja consultar'
                "
                v-model="modeloAddSelecionado"
                name="modelos"
                :disabled="listaModelosFiltrados.length == 0"
                @input="selecionaModelo"
              >
                <md-input
                  class="search"
                  v-model="search"
                  placeholder="Pesquisar"
                ></md-input>
                <span
                  v-for="(item, index) in listaModelosFiltrados"
                  :key="index"
                >
                  <md-option
                    :value="item.Valor"
                    :id="'item.Valor-' + index"
                    style="margin-top: 5px"
                    :disabled="consulta.consultado.includes(item.Valor)"
                    :class="{
                      travado: consulta.consultado.includes(item.Valor),
                    }"
                  >
                    {{ item.Valor }}
                    <span
                      class="option-descricao"
                      v-if="item.Descricao && item.Descricao != '-'"
                    >
                      {{ item.Descricao || "" }}
                    </span>
                  </md-option>
                </span>
              </md-select>
            </md-field>
            <md-field class="campo-selecao" v-if="modeloAddSelecionado">
              <md-select
                placeholder=" Selecione consultas adicionais do modelo"
                v-model="valorConsultasSelecionadas"
                name="consultas"
                multiple
              >
                <md-input
                  class="search"
                  v-model="searchAdd"
                  placeholder="Pesquisar"
                ></md-input>
                <span
                  v-for="(item, index) in consultasAdicionaisPesquisadas"
                  :key="index"
                >
                  <md-option
                    :value="item.Valor"
                    :id="'item.Valor-' + index"
                    style="margin-top: 5px"
                  >
                    {{ item.Nome }}
                  </md-option>
                </span>
              </md-select>
            </md-field>
          </div>
        </div>
      </div>
      <div class="consulta-container" v-if="pagina == 2">
        <SelecaoConsAddPreenchimento
          :parametrosUtilizados="parametrosUtilizados"
          @consultar="consultar"
        />
      </div>
    </div>
    <div class="button-box" :class="{ pag2: pagina == 2 }">
      <md-button
        v-if="pagina == 2"
        native-type="submit"
        class="md-primary button-selecao"
        @click="pagina = 1"
      >
        Voltar
      </md-button>
      <md-button
        v-if="pagina == 1 && liberaBotaoProximo"
        native-type="submit"
        class="md-primary button-selecao"
        @click="pagina = 2"
      >
        Próximo
      </md-button>
      <md-button
        v-if="!liberaBotaoProximo || (liberaBotaoConsultar && pagina == 2)"
        native-type="submit"
        class="md-primary button-selecao"
        :disabled="liberaConsultar"
        @click="consultar()"
      >
        Consultar
      </md-button>
    </div>
  </div>
</template>

<script>
import { userPreferenceStore } from "@/stores/userPreference";
import { produtosStore } from "@/stores/produtos";
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { mapActions, mapWritableState } from "pinia";
import { lotesService } from "@/services";
import produtosSuperBoxJson from "@/helpers/produtos.json";
import inputsModelos from "@/helpers/inputs_modelos.json";
import SelecaoConsAddPreenchimento from "@/components/Modelos/SelecaoConsAddPreenchimento.vue";

export default {
  name: "SelecaoConsultaAdicional",

  components: { SelecaoConsAddPreenchimento },

  data() {
    return {
      search: "",
      searchAdd: "",
      listaTiposConsulta: [
        {
          nome: "Consulta Superbox",
          valor: "Superbox",
          tipo: "PF",
          consultado: [],
        },
        {
          nome: "Consulta de Modelos PF",
          valor: "Modelos",
          tipo: "PF",
          consultado: [],
        },
        {
          nome: "Consulta de Modelos PJ",
          valor: "Modelos",
          tipo: "PJ",
          consultado: [],
        },
      ],

      tipoConsulta: "",
      modeloAddSelecionado: "",
      paramentoCep: "",
      paramentoDataRef: "",
      valorConsultasSelecionadas: [],
      consultasSelecionadas: [],

      pagina: 1,
      layoutModelo: {},
      parametrosUtilizados: [],
      liberaBotaoProximo: false,
      liberaBotaoConsultar: false,
      objetoParametros: {},
    };
  },

  props: {
    documento: String,
    tipo: String,
  },

  watch: {},

  methods: {
    async selecionaModelo() {
      this.consultasAdicionaisPesquisadas.forEach((obj) => {
        if (this.modelosConsultasAddFavoritos.includes(obj.Nome)) {
          this.valorConsultasSelecionadas.push(obj.Valor);
        }
      });

      if (
        this.valorConsultasSelecionadas.includes("BOXSUPPJ001") &&
        this.valorConsultasSelecionadas.includes("BOXSUPPJ002")
      ) {
        this.valorConsultasSelecionadas.push("BOXSUPPJ009");
      }

      await this.recuperarLayoutModelo();
    },

    async recuperarLayoutModelo() {
      this.layoutModelo = {};

      const objetoHistorico = {
        cod_ref: this.modeloAddSelecionado,
      };
      const query = new URLSearchParams(objetoHistorico);
      this.layoutModelo = await lotesService.recuperaLayoutModelo(
        query.toString()
      );
      this.liberaBotaoProximo = false;

      await this.verificaParametros();
      if (this.parametrosUtilizados.length > 0) {
        this.liberaBotaoProximo =
          this.parametrosUtilizados.filter((obj) => obj.visivel === true)
            .length > 0;
      }
    },

    async verificaParametros() {
      this.parametrosUtilizados = [];
      this.liberaBotaoConsultar = false;
      const chaveExistente = Object.keys(this.listaInputs).includes(
        this.modeloAddSelecionado
      );

      let listaUtilizada;
      if (chaveExistente) {
        const listaSelecionada = this.listaInputs[this.modeloAddSelecionado];
        const listaPadrao = this.listaInputs["Padrao"];

        const nomesApiEncontrados = new Set();

        const listaPadraoFiltrada = listaPadrao.filter((obj) => {
          const nomeApi = obj.nome_api;
          const jaEncontrado = nomesApiEncontrados.has(nomeApi);

          if (!jaEncontrado) {
            nomesApiEncontrados.add(nomeApi);
            return true;
          }

          return false;
        });

        listaUtilizada = listaSelecionada.concat(listaPadraoFiltrada);
      } else {
        listaUtilizada = this.listaInputs["Padrao"];
      }

      let listaParametros = this.layoutModelo.ListaParametros;

      const identificadoresAdicionados = new Set();

      for (const item of listaParametros) {
        const listaCorrespondente = listaUtilizada.find(
          (lista) => lista.nome_api === item.Identificador
        );

        if (listaCorrespondente) {
          const combinedObj = this.mesclarObjetos(listaCorrespondente, item);
          delete combinedObj.Identificador;

          if (!identificadoresAdicionados.has(combinedObj.nome_api)) {
            this.parametrosUtilizados.push(combinedObj);
            identificadoresAdicionados.add(combinedObj.nome_api);
          }
        } else {
          item.nome_api = item.Identificador;
          item.visivel = true;
          item.nome_amigavel = item.nome_api;
          delete item.Identificador;

          if (!identificadoresAdicionados.has(item.nome_api)) {
            this.parametrosUtilizados.push(item);
            identificadoresAdicionados.add(item.nome_api);
          }
        }
      }

      if (this.usuarioLogado.Empresa == "StepWise") {
        let objetoEncontrado = this.parametrosUtilizados.find(
          (obj) => obj.nome_api === "data_ref"
        );
        if (objetoEncontrado) {
          objetoEncontrado.visivel = true;
          objetoEncontrado.Obrigatorio = false;
        }
      }

      const indexDoc = this.parametrosUtilizados.findIndex(
        (item) =>
          item.Identificador === "cpf_cnpj" || item.nome_amigavel == "cpf_cnpj"
      );
      if (indexDoc !== -1) {
        this.parametrosUtilizados.splice(indexDoc, 1);
      }

      const indexCep = this.parametrosUtilizados.findIndex(
        (item) => item.nome_api == "cep"
      );
      if (indexCep !== -1) {
        this.parametrosUtilizados.splice(indexCep, 1);
      }

      const indexData = this.parametrosUtilizados.findIndex(
        (item) => item.nome_api == "data_ref"
      );
      if (indexData !== -1) {
        this.parametrosUtilizados.splice(indexData, 1);
      }

      this.parametrosUtilizados = this.parametrosUtilizados.filter(
        (item) => item.visivel
      );

      this.parametrosUtilizados.sort((a, b) => {
        if (a.Obrigatorio !== b.Obrigatorio) {
          return a.Obrigatorio ? -1 : 1;
        } else {
          if (a.nome_api < b.nome_api) {
            return -1;
          } else if (a.nome_api > b.nome_api) {
            return 1;
          } else {
            return 0;
          }
        }
      });

      this.liberaBotaoConsultar = true;
    },

    mesclarObjetos(obj1, obj2) {
      const resultado = {};
      for (const chave in obj1) {
        resultado[chave] = obj1[chave];
      }
      for (const chave in obj2) {
        resultado[chave] = obj2[chave];
      }
      return resultado;
    },

    recuperaParametros() {
      var objetoResultado = {};

      this.parametrosUtilizados.forEach((parametro) => {
        if (parametro.tipo_input == "data" || parametro.Tipo == "Data") {
          objetoResultado[parametro.nome_api] =
            parametro.ValorDatePicker !== undefined
              ? parametro.ValorDatePicker
              : this.formatDateYMD(parametro.Valor);
        } else {
          objetoResultado[parametro.nome_api] =
            parametro.Valor !== undefined ? parametro.Valor : "";
        }
      });

      for (var chave in objetoResultado) {
        if (
          objetoResultado[chave] != null &&
          objetoResultado[chave] != undefined
        ) {
          if (
            objetoResultado[chave].includes("R$") &&
            typeof objetoResultado[chave] == "string"
          ) {
            objetoResultado[chave] = objetoResultado[chave].replace("R$", "");
            objetoResultado[chave] = objetoResultado[chave].replace(/\./g, "");
            objetoResultado[chave] = objetoResultado[chave].replace(",", ".");
            objetoResultado[chave] = objetoResultado[chave].replace(/\s/g, "");
          } else if (
            objetoResultado[chave].includes(",") &&
            typeof objetoResultado[chave] == "string"
          ) {
            objetoResultado[chave] = objetoResultado[chave].replace(",", ".");
          }
        }
      }

      this.objetoParametros = objetoResultado;
    },

    formatDateYMD(input) {
      if (input instanceof Date) {
        const year = input.getFullYear();
        const month = String(input.getMonth() + 1).padStart(2, "0");
        const day = String(input.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      } else if (typeof input === "string") {
        const parts = input.split("/");
        if (parts.length === 3) {
          const year = parts[2];
          const month = String(parts[1]).padStart(2, "0");
          const day = String(parts[0]).padStart(2, "0");
          return `${year}-${month}-${day}`;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },

    consultar() {
      if (!this.liberaConsultar) {
        if (this.parametrosUtilizados.length > 0) {
          this.recuperaParametros();
        }

        this.consultasSelecionadas = this.consultasAdicionais.filter(
          (consulta) => this.valorConsultasSelecionadas.includes(consulta.Valor)
        );

        this.ativaTrilhaConsultas = false;
        this.tipoConsultaSelecionada = this.tipoConsulta;
        var novoId = this.consultasTrilha.length + 1;
        var novaConsulta = {};
        if (this.tipoConsultaSelecionada == "Modelos") {
          const modeloSelecionado =
            this.modelos.find(
              (modelo) => modelo.Valor == this.modeloAddSelecionado
            ) || null;

          this.codRefSelecionado = this.modeloAddSelecionado;

          novaConsulta = {
            id: novoId,
            origem: this.trilhaSelecionada.id,
            cod_agrupador: "loading",
            tipo_pessoa: this.tipoDocumentoConsultar,
            consulta: this.tipoConsultaSelecionada,
            modeloSelecionado: modeloSelecionado,
            consultasSelecionadas: this.consultasSelecionadas,
            parametros: {
              [this.tipoDocumentoConsultar == "PF" ? "cpf" : "cnpj"]:
                this.documento,
              cep: this.paramentoCep,
              ...this.objetoParametros,
            },
            historico: {},
          };
        } else if (this.tipoConsultaSelecionada == "Superbox") {
          novaConsulta = {
            id: novoId,
            origem: this.trilhaSelecionada.id,
            cod_agrupador: "loading",
            tipo_pessoa: this.tipoDocumentoConsultar,
            consulta: this.tipoConsultaSelecionada,
            consultasSelecionadas: this.consultasSelecionadas,
            modeloSelecionado: "",
            parametros: {
              [this.tipoDocumentoConsultar == "PF" ? "cpf" : "cnpj"]:
                this.documento,
              cep: this.paramentoCep,
              data_ref: this.dataDaConsulta,
              ...this.objetoParametros,
            },
            historico: {},
          };
        }

        this.consultasTrilha.push(novaConsulta);
        this.ativaTrilhaConsultas = true;

        this.fecharSelecaoConsulta();
      }
    },

    fecharConsultaAdd() {
      this.fecharSelecaoConsulta();
    },

    formatarCPF(cpf) {
      if (cpf && cpf != "-") {
        cpf = cpf.replace(/\D/g, "");

        cpf = cpf.replace(/^(\d{3})(\d)/, "$1.$2");
        cpf = cpf.replace(/^(\d{3})\.(\d{3})(\d)/, "$1.$2.$3");
        cpf = cpf.replace(/\.(\d{3})(\d)/, ".$1-$2");

        if (this.ocultarDados) {
          return cpf.replace(
            /(\d{3})\.(\d{3})\.(\d{3})-(\d{2})/,
            "***.$2.$3-**"
          );
        } else {
          return cpf;
        }
      }
      return cpf;
    },

    formatarCNPJ(cnpj) {
      if (cnpj != null && cnpj != "-") {
        cnpj = cnpj.replace(/\D/g, "");
        cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2");
        cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
        cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
        cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");
        if (this.ocultarDados) {
          cnpj = cnpj.replace(
            /(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})\-(\d{2})/,
            (match, p1, p2, p3, p4, p5) => `${p1}.***.***/****-${p5}`
          );
        }
      }

      return cnpj;
    },

    aplicaMascara(tipo, value) {
      if (tipo == "cep") {
        let cep = value.replace(/[^0-9-]/g, "");
        cep = cep.replace(/\D/g, "");

        if (cep.length > 5) {
          cep = cep.slice(0, 5) + "-" + cep.slice(5, 8);
        }

        this.paramentoCep = cep;
      }

      if (tipo == "data_ref") {
        let formatted = "";
        let index = 0;
        const mask = "##/##/####";
        const tamanho = 10;
        const inputValue = value.replace(/[^0-9]+/g, "");
        let result = inputValue.slice(0, tamanho);

        for (let i = 0; i < mask.length && index < result.length; i++) {
          const maskChar = mask[i];
          if (maskChar === "#") {
            formatted += result[index] || "";
            index++;
          } else {
            formatted += maskChar;
          }
        }
        this.paramentoDataRef = formatted;
      }
      //  else {
      //   this.paramentoDataRef = value;
      // }
    },

    verificaSelecaoInicial() {
      if (this.tipo == "PF") {
        this.tipoConsulta = "Superbox";
      } else if (this.tipo == "PJ") {
        this.tipoConsulta = "Modelos";
      }

      if (this.usuarioSemAcesso) {
        this.tipoConsulta = "Modelos";
      }

      if (this.documentoFormatado in this.documentosAgrupados) {
        const consultasRealizadas =
          this.documentosAgrupados[this.documentoFormatado].consultasRealizadas;
        if (consultasRealizadas.includes("Superbox")) {
          this.tipoConsulta = "Modelos";
        }
      }
    },

    ...mapActions(produtosStore, ["getModelos"]),

    ...mapActions(dadosConsultaAddStore, [
      "abrirSelecaoConsulta",
      "fecharSelecaoConsulta",
    ]),
  },

  computed: {
    usuarioSemAcesso() {
      return (
        !this.usuarioLogado.Grupos.includes(
          "Usuários StepWise com acesso ao SuperBox"
        ) &&
        !this.usuarioLogado.Grupos.includes(
          "Acesso de clientes ao Portal Outbox - SuperBox"
        )
      );
    },

    liberaConsultar() {
      if (this.tipoConsulta == "Modelos") {
        if (this.modeloAddSelecionado == "") {
          return true;
        } else if (this.parametrosUtilizados.length > 0) {
          const obrigatorioInvalido = this.parametrosUtilizados.some(
            (parametro) => {
              return (
                parametro.Obrigatorio &&
                parametro.visivel &&
                parametro.Classe !== "md-valid"
              );
            }
          );

          const edicaoErro = this.parametrosUtilizados.some((parametro) => {
            const classe = parametro.Classe;
            return classe === "md-edit" || classe === "md-error";
          });

          return obrigatorioInvalido || edicaoErro;
        }
      }

      return false;
    },

    documentosAgrupados() {
      const resultado = {};

      this.consultasTrilha.forEach((item) => {
        const documento =
          this.formatarCPF(item.parametros.cpf) ||
          this.formatarCNPJ(item.parametros.cnpj);

        // if (documento && Object.keys(item.parametros).length <= 2) {
        if (!resultado[documento]) {
          this.$set(resultado, documento, {
            consultasRealizadas: [],
            modelosConsultados: [],
          });
        }

        if (!resultado[documento].consultasRealizadas.includes(item.consulta)) {
          resultado[documento].consultasRealizadas.push(item.consulta);
        }

        if (
          item.modeloSelecionado.Valor &&
          !resultado[documento].modelosConsultados.includes(
            item.modeloSelecionado.Valor
          )
        ) {
          resultado[documento].modelosConsultados.push(
            item.modeloSelecionado.Valor
          );
        }
        // }
      });

      return resultado;
    },

    listaTiposConsultaFiltrada() {
      return this.listaTiposConsulta
        .filter((consulta) => {
          if (consulta.tipo !== this.tipo) return false;

          if (this.usuarioSemAcesso && consulta.valor === "Superbox") {
            return false;
          }
          return true;
        })
        .map((consulta) => {
          if (this.documentoFormatado in this.documentosAgrupados) {
            const consultasRealizadas =
              this.documentosAgrupados[this.documentoFormatado]
                .consultasRealizadas;
            const consultasModelosRealizadas =
              this.documentosAgrupados[this.documentoFormatado]
                .modelosConsultados;

            if (
              consulta.valor === "Superbox" &&
              consultasRealizadas.includes(consulta.valor)
            ) {
              consulta.consultado.push("Superbox");
            } else if (consulta.valor === "Modelos") {
              consulta.consultado = consultasModelosRealizadas;
            }
          }
          return consulta;
        });
    },

    documentoFormatado() {
      if (this.tipo == "PF") {
        return this.formatarCPF(this.documento);
      } else if (this.tipo == "PJ") {
        return this.formatarCNPJ(this.documento);
      } else {
        return "";
      }
    },

    listaModelos() {
      const modelosOrdenados = this.modelos.slice().sort((a, b) => {
        const aIndex = this.modelosPreferidos.indexOf(a.Valor);
        const bIndex = this.modelosPreferidos.indexOf(b.Valor);

        if (aIndex === -1) return 1;
        if (bIndex === -1) return -1;

        return aIndex - bIndex;
      });

      if (this.tipo == "PF") {
        return modelosOrdenados.filter(
          (modelo) =>
            (modelo.TipoPessoaConsulta === "Física" ||
              modelo.TipoPessoaConsulta === "Indiferente") &&
            modelo.Valor !== null &&
            modelo.Valor !== undefined &&
            modelo.Descricao !== "-"
        );
      } else if (this.tipo == "PJ") {
        return modelosOrdenados.filter(
          (modelo) =>
            (modelo.TipoPessoaConsulta === "Jurídica" ||
              modelo.TipoPessoaConsulta === "Indiferente") &&
            modelo.Valor !== null &&
            modelo.Valor !== undefined &&
            modelo.Descricao !== "-"
        );
      } else {
        return [];
      }
    },

    listaModelosFiltrados() {
      if (this.search !== "") {
        const search = this.search.toLowerCase();

        return this.listaModelos.filter((item) => {
          const descricao = item.Descricao.toLowerCase();
          const valor = item.Valor.toLowerCase();
          return descricao.includes(search) || valor.includes(search);
        });
      } else {
        return this.listaModelos;
      }
    },

    consultasAdicionais() {
      const arrysuperbox = produtosSuperBoxJson.produtos;

      if (this.tipo == "PF") {
        var produtosFiltrados = this.produtos.filter(
          (produto) =>
            produto.Nome === "Dados Cadastrais" ||
            produto.Nome === "Mapa Geral de Riscos"
        );
        return produtosFiltrados.map((consulta) => {
          return {
            ...consulta,
            Valor: consulta.Box,
          };
        });
      } else if (this.tipo == "PJ") {
        var produtosFiltrados = this.produtos
          .filter(
            (produto) =>
              produto.Nome === "Informações Cadastrais" ||
              produto.Nome === "Detalhamentos Cadastrais da Matriz"
          )
          .map((consulta) => {
            return {
              ...consulta,
              Valor: consulta.Box,
            };
          });

        var prodAdicional = [];
        if (produtosFiltrados.length == 2) {
          prodAdicional = arrysuperbox
            .filter((item) => item.Box === "BOXSUPPF009")
            .map((item) => {
              return {
                ...item,
                TipoProduto: "PJ",
                Valor: "BOXSUPPJ009",
              };
            });
        } else {
          var produtosFiltrados = this.produtos;
        }
      }
      return produtosFiltrados.concat(prodAdicional);
    },

    consultasAdicionaisPesquisadas() {
      if (this.searchAdd !== "") {
        const pesquisaMinuscula = this.searchAdd.toLowerCase();

        const objetosFiltrados = this.consultasAdicionais.filter((objeto) => {
          const valorContem =
            objeto.Nome.toLowerCase().includes(pesquisaMinuscula);
          const descricaoContem =
            objeto.Valor.toLowerCase().includes(pesquisaMinuscula);

          return valorContem || descricaoContem;
        });

        return objetosFiltrados;
      } else {
        return this.consultasAdicionais;
      }
    },

    listaInputs() {
      return inputsModelos;
    },

    ...mapWritableState(userPreferenceStore, {
      usuarioLogado: "usuarioLogado",
      modelosPreferidos: "modelosPreferidos",
      modelosConsultasAddFavoritos: "modelosConsultasAddFavoritos",
      ocultarDados: "ocultarDados",
    }),

    ...mapWritableState(dadosConsultaAddStore, {
      ativaTrilhaConsultas: "ativaTrilhaConsultas",
      consultasTrilha: "consultasTrilha",
      tipoConsultaSelecionada: "tipoConsultaSelecionada",
      codRefSelecionado: "codRefSelecionado",
      trilhaSelecionada: "trilhaSelecionada",
      tipoDocumentoConsultar: "tipoDocumentoConsultar",
      dataDaConsulta: "dataDaConsulta",
    }),

    ...mapWritableState(produtosStore, {
      produtos: "produtos",
      modelos: "modelos",
    }),
  },

  async mounted() {
    this.verificaSelecaoInicial();
    // await this.getModelos();
  },
};
</script>

<style lang="scss" scoped>
.menu-container {
  position: fixed;
  z-index: 10;
  width: 750px;
  top: 10%;
  // left: calc(50% - 220px);
  right: calc(50%);
  transform: translateX(50%) translateX(130px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
}

.content {
  margin: 0;
}

.titulo {
  color: #4356a5;
  font-size: 21px;
  line-height: 28px;
  font-weight: 500;
}

.close-icon {
  position: absolute;
  right: 15px;
  cursor: pointer;
}

.box-dados {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  gap: 20px;
}

.doc {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 5px;
  flex-direction: column;
  padding: 0 10px;
}

.label {
  font-size: 12px;
  font-weight: 300;
  margin: 0 0 0 10px;
  position: relative;
  top: 7px;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
}

.texto {
  font-size: 14px;
  font-weight: 300;
}

.valor {
  font-size: 14px;
  font-weight: 400;
}

.consulta-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 0 10px;
}

.consulta-itens {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.box-consulta {
  display: flex;
  width: 100%;
  height: 70px;
  max-width: 960px;
  background-image: url(/img/modelos/Topo_Modelos.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  // margin: 10px 0 -20px 0;

  &.selecionado {
    background-image: url(/img/modelos/Topo_Modelos-preenchido.png);
    .header-text {
      color: white;
    }
  }
}

.travado {
  opacity: 0.5;
  pointer-events: none;
}

.header-text {
  display: flex;
  flex-direction: column;
  color: #4356a5;
  width: 100%;
  align-items: flex-start;
  margin: 0 20px 0 25%;
  line-height: 21px;
  gap: 5px;

  .text-top {
    font-size: 26px;
    line-height: 28px;
    font-weight: 400;
  }
}

.opcoes-modelo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}

.campo-selecao {
  width: 300px;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 0px 10px 15px;

  &.grande {
    width: 350px;
  }
}

.search {
  display: flex;
  width: 100%;
  max-width: 300px;
  height: 35px;
  border-width: 0px 0px 1px 0px;
  font-size: small;
  margin: 0 0px;
  padding: 0 10px;

  &.disabled {
    pointer-events: none;
    color: gray;
  }
}

.option-descricao {
  font-size: 10px;
  line-height: 10px;
  font-weight: 300;
  padding-left: 0 !important;
}

.button-box {
  width: 100%;
  display: flex;
  padding: 0 20px;
  justify-content: flex-end;

  &.pag2 {
    justify-content: space-between;
  }
}

.button-selecao {
  width: 120px;
}
</style>

<style>
.campo-selecao > .md-menu-content.md-select-menu {
  width: 400px !important;
  max-width: 400px !important;
}
</style>
